'use strict'

export const Loading = {
  extend: 'Flex',
  props: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 1px)',
    fontSize: 'A2',
    backgroundColor: 'charm',
    opacity: 1,
    zIndex: 10
  },
  Loader: {
    text: 'Loading...'
  }
}
