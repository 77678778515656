'use strict'

const pacmanSvg = new URL('../assets/pacman.svg', import.meta.url)
export const Pacman = {
  props: {
    backgroundColor: 'none',
    backgroundImage: pacmanSvg.toString(),
    backgroundSize: '160px',
    backgroundPosition: '40px 0',
    width: '40px',
    height: '40px',
    borderRadius: '0',
    transform: 'translateX(-20px)',
    '&.looping': {
      animation: 'pacman',
      animationDuration: '350ms',
      animationIterationCount: 'infinite'
    }
  }
}

export const Pinky = {
  props: ({ key }) => ({
    backgroundColor: 'none',
    backgroundSize: '76px',
    backgroundPosition: '76px 0',
    width: '38px',
    height: '38px',
    borderRadius: '0',
    position: 'relative',
    id: key.split('.')[1],
    cursor: 'pointer',

    '&.active, &.recording': {
      animation: 'pinky',
      animationDuration: '350ms',
      animationIterationCount: 'infinite'
    }
  }),

  Text: ({ key }) => ({
    position: 'absolute',
    top: '110%',
    left: '50%',
    textAlign: 'center',
    width: '200%',
    whiteSpace: 'normal',
    transform: 'translate3d(-50%, 0, 1px)',
    fontSize: 'Y2',
    text: key.split('.')[1]
  })
}

export const Clyde = {
  extend: 'Pinky'
}

export const Blinky = {
  extend: 'Pinky'
}

export const BlinkyAngry = {
  extend: 'Pinky'
}

export const BlinkyAnnoyed = {
  extend: 'Pinky'
}

export const Inky = {
  extend: 'Pinky'
}

const scaredBackground = new URL(
  '../assets/scared_blue.svg',
  import.meta.url
).toString()
export const Scared = {
  extend: 'Pinky',
  props: {
    backgroundImage: scaredBackground,
    '.playing': {
      animation: 'kinky',
      animationDuration: '350ms',
      animationIterationCount: 'infinite'
    }
  }
}

export const Cherry = {
  extend: 'Pinky',
  props: {
    backgroundColor: 'none',
    backgroundSize: '38px',
    backgroundPosition: '0 0',
    whiteSpace: 'nowrap',

    '&.active, &.recording': {
      animation: 'cherry',
      animationDuration: '350ms',
      animationIterationCount: 'infinite'
    }
  }
}

export const Bell = {
  extend: 'Cherry'
}

export const Key = {
  extend: 'Cherry'
}

export const Galaxian = {
  extend: 'Cherry'
}

export const Apple = {
  extend: 'Cherry'
}

export const Melon = {
  extend: 'Cherry'
}

export const Orange = {
  extend: 'Cherry'
}

export const Strawberry = {
  extend: 'Cherry'
}
