'use strict'

export const Page = {
  extend: 'Flex',
  props: {
    flow: 'column',
    boxSize: '100%',
    padding: 'C1 C1 D1',
    align: 'center space-between'
  }
}

export const Divider = {
  props: {
    ':before': {
      content: '"..."',
      display: 'inline-block',
      margin: 'B1',
      opacity: '.35',
      position: 'relative'
    }
  }
}
