'use strict'

import { About } from './About'
import { About2 } from './About2'
import { ComingSoon } from './ComingSoon'
import { Main } from './Main'
import { Events } from './Events'
// import { Controller } from './Controller'

export default {
  '/': ComingSoon,
  '/main': Main,
  // '/controller': Controller,
  '/events': Events,
  '/about': About,
  '/about2': About2,
  '/contact': { text: 'contact' }
}
