import P5 from '../../modules/p5.min'
// class Particle {
//   constructor(p) {
//     this.p = p
//     this.reset()
//   }
//
//   reset() {
//     this.pos = this.p.createVector(
//       this.p.random(this.p.windowWidth),
//       this.p.random(this.p.windowHeight)
//     )
//     this.vel = this.p.createVector(
//       this.p.random(-0.5, 0.5),
//       this.p.random(-0.5, 0.5)
//     )
//     this.size = this.p.random(1, 5)
//     this.noiseOffsetX = this.p.random(1000)
//     this.noiseOffsetY = this.p.random(1000)
//   }
//
//   move() {
//     // Update the noise offset for smooth, natural movement
//     this.noiseOffsetX += 0.02
//     this.noiseOffsetY += 0.02
//
//     // Using Perlin noise for smooth, natural motion
//     let noiseStrength = 0.5
//     this.vel.x += (this.p.noise(this.noiseOffsetX) - 0.5) * noiseStrength
//     this.vel.y += (this.p.noise(this.noiseOffsetY) - 0.5) * noiseStrength
//
//     // Apply the velocity to the position
//     this.pos.add(this.vel)
//
//     // Optional: Slowly reduce the velocity to simulate drag
//     this.vel.mult(0.99)
//
//     // Reset particle if it goes off the screen
//     if (
//       this.pos.x < 0 ||
//       this.pos.x > this.p.windowWidth ||
//       this.pos.y < 0 ||
//       this.pos.y > this.p.windowHeight
//     ) {
//       this.reset()
//     }
//   }
//
//   display() {
//     this.p.noStroke()
//     this.p.fill(255, 100)
//     this.p.circle(this.pos.x, this.pos.y, this.size)
//   }
// }
// class Particle {
//   constructor(p) {
//     this.p = p
//     this.reset()
//   }
//
//   reset() {
//     this.pos = this.p.createVector(
//       this.p.random(this.p.windowWidth),
//       this.p.random(this.p.windowHeight)
//     )
//     // Slightly higher initial velocities
//     this.vel = this.p.createVector(this.p.random(-1, 1), this.p.random(-1, 1))
//     this.size = this.p.random(1, 5)
//     this.noiseOffsetX = this.p.random(1000)
//     this.noiseOffsetY = this.p.random(1000)
//     this.wind = this.p.createVector(0, 0) // subtle wind effect
//   }
//
//   move() {
//     // Calculate wind direction based on mouse position
//     let windDirection = this.p.createVector(
//       this.p.mouseX - this.p.width / 2,
//       this.p.mouseY - this.p.height / 2
//     )
//     windDirection.normalize() // Normalize to get direction only
//     this.wind.lerp(windDirection, 0.01) // Smooth transition of wind direction
//
//     // Slow and natural movement with noise
//     this.noiseOffsetX += 0.01
//     this.noiseOffsetY += 0.01
//
//     let noiseStrength = 0.1
//     this.vel.x +=
//       (this.p.noise(this.noiseOffsetX) - 0.5) * noiseStrength + this.wind.x
//     this.vel.y +=
//       (this.p.noise(this.noiseOffsetY) - 0.5) * noiseStrength + this.wind.y
//
//     // Apply the velocity to the position
//     this.pos.add(this.vel)
//
//     // Limit the velocity to keep movement gentle
//     this.vel.limit(0.2)
//
//     // Reset particle if it goes off the screen
//     if (
//       this.pos.x < 0 ||
//       this.pos.x > this.p.windowWidth ||
//       this.pos.y < 0 ||
//       this.pos.y > this.p.windowHeight
//     ) {
//       this.reset()
//     }
//   }
//
//   // move() {
//   // // Increase the noise offset increment for more dynamic movement
//   // this.noiseOffsetX += 0.005
//   // this.noiseOffsetY += 0.005
//   //
//   // // Increase the strength of noise influence on the velocity
//   // let noiseStrength = 0.2
//   // this.vel.x += (this.p.noise(this.noiseOffsetX) - 0.5) * noiseStrength
//   // this.vel.y += (this.p.noise(this.noiseOffsetY) - 0.5) * noiseStrength
//   //
//   // // Apply the velocity to the position
//   // this.pos.add(this.vel)
//   //
//   // // Optional: Apply a drag effect
//   // this.vel.mult(0.95)
//   //
//   // // Reset particle if it goes off the screen
//   // if (
//   //   this.pos.x < 0 ||
//   //   this.pos.x > this.p.windowWidth ||
//   //   this.pos.y < 0 ||
//   //   this.pos.y > this.p.windowHeight
//   // ) {
//   //   this.reset()
//   // }
//
//   // Slow and subtle movement
//   //   this.noiseOffsetX += 0.01
//   //   this.noiseOffsetY += 0.01
//   //
//   //   let noiseStrength = 0.1 // Reduced strength for subtle movement
//   //   this.vel.x += (this.p.noise(this.noiseOffsetX) - 0.5) * noiseStrength
//   //   this.vel.y += (this.p.noise(this.noiseOffsetY) - 0.5) * noiseStrength
//   //
//   //   // Random directional changes
//   //   if (this.p.frameCount % 60 === 0) {
//   //     // Change direction approximately every second
//   //     this.vel.x += this.p.random(-0.5, 0.5)
//   //     this.vel.y += this.p.random(-0.5, 0.5)
//   //   }
//   //
//   //   // Apply the velocity to the position
//   //   this.pos.add(this.vel)
//   //
//   //   // Limit the velocity to keep movement gentle
//   //   this.vel.limit(0.5) // Limit to a small value
//   // }
//
//   display() {
//     this.p.noStroke()
//     this.p.fill(255, 100)
//     this.p.circle(this.pos.x, this.pos.y, this.size)
//   }
// }

// class Particle {
//   constructor(p) {
//     this.p = p
//     this.reset()
//     this.windInfluence = this.p.random(0.05, 0.7) // Random influence factor for each particle
//   }
//
//   reset() {
//     this.pos = this.p.createVector(
//       this.p.random(this.p.windowWidth),
//       this.p.random(this.p.windowHeight)
//     )
//     this.vel = this.p.createVector(0, 0)
//     this.size = this.p.random(1, 5)
//     this.noiseOffsetX = this.p.random(1000)
//     this.noiseOffsetY = this.p.random(1000)
//   }
//
//   move(wind) {
//     // Slow and natural movement with noise
//     this.noiseOffsetX += 0.01
//     this.noiseOffsetY += 0.01
//
//     let noiseStrength = 0.1
//     this.vel.x +=
//       (this.p.noise(this.noiseOffsetX) - 0.5) * noiseStrength +
//       wind.x * this.windInfluence
//     this.vel.y +=
//       (this.p.noise(this.noiseOffsetY) - 0.5) * noiseStrength +
//       wind.y * this.windInfluence
//
//     // Apply the velocity to the position
//     this.pos.add(this.vel)
//
//     // Limit the velocity to keep movement gentle
//     this.vel.limit(0.2)
//
//     // Reset particle if it goes off the screen
//     if (
//       this.pos.x < 0 ||
//       this.pos.x > this.p.windowWidth ||
//       this.pos.y < 0 ||
//       this.pos.y > this.p.windowHeight
//     ) {
//       this.reset()
//     }
//   }
//
//   display() {
//     this.p.noStroke()
//     this.p.fill(255, 100)
//     this.p.circle(this.pos.x, this.pos.y, this.size)
//   }
// }
//

const getElementPosition = (p, el) => {
  const rect = el.getBoundingClientRect()
  return p.createVector(rect.left + rect.width / 2, rect.top + rect.height / 2)
}

const avoidElement = (p, particlePos) => {
  const el = document.querySelector('.events')
  if (!el) return p.createVector(0, 0) // Ensure the element exists

  const elPosition = getElementPosition(p, el)
  const distance = p.dist(
    particlePos.x,
    particlePos.y,
    elPosition.x,
    elPosition.y
  )

  // Calculate a repulsion force if within a certain distance
  if (distance < el.offsetWidth / 2) {
    // Adjust this threshold as needed
    const force = P5.Vector.sub(particlePos, elPosition) // Direction from element to particle
    force.normalize() // Normalize to get direction
    force.div(10) // Weaker force with greater distance
    return force
  }

  return p.createVector(0, 0) // No force if not close to the element
}

let isHovering = false // Flag to track hover state

// Function to add event listeners
const setupDivHoverListener = (divId) => {
  const div = document.getElementById(divId)
  if (!div) return
  div.addEventListener('mouseenter', function () {
    isHovering = true
    console.log('hovering')
  })
  div.addEventListener('mouseleave', function () {
    isHovering = false
    console.log('not hovering')
  })
}

class Particle {
  constructor (p) {
    this.p = p
    this.reset()
  }

  reset () {
    this.pos = this.p.createVector(
      this.p.random(this.p.windowWidth),
      this.p.random(this.p.windowHeight)
    )
    this.vel = this.p.createVector(0, 0)
    this.size = this.p.random(1, 3)
    this.noiseOffsetX = this.p.random(1000)
    this.noiseOffsetY = this.p.random(1000)
    this.windInfluence = this.p.random(0.02, 0.4) // Varying wind influence
    this.windChangeTimer = this.p.floor(this.p.random(60, 240)) // Random timer for changing wind response
  }

  move (wind) {
    // Decrease timer and possibly change wind influence
    if (this.windChangeTimer <= 0) {
      this.windInfluence = this.p.random(0.02, 0.1) // Change wind influence randomly
      this.windChangeTimer = this.p.floor(this.p.random(60, 240)) // Reset timer
    } else {
      this.windChangeTimer--
    }

    // Slow and natural movement with noise
    this.noiseOffsetX += 0.01
    this.noiseOffsetY += 0.01

    const noiseStrength = 0.1
    this.vel.x +=
      (this.p.noise(this.noiseOffsetX) - 0.5) * noiseStrength +
      wind.x * this.windInfluence
    this.vel.y +=
      (this.p.noise(this.noiseOffsetY) - 0.5) * noiseStrength +
      wind.y * this.windInfluence

    // Apply the velocity to the position if hovering
    if (isHovering) {
      const avoidanceForce = avoidElement(this.p, this.pos)
      this.vel.add(avoidanceForce) // Apply avoidance force to velocity
    }

    // Apply the velocity to the position
    this.pos.add(this.vel)

    // Limit the velocity to keep movement gentle
    this.vel.limit(0.2)

    // Reset particle if it goes off the screen
    if (
      this.pos.x < 0 ||
      this.pos.x > this.p.windowWidth ||
      this.pos.y < 0 ||
      this.pos.y > this.p.windowHeight
    ) {
      this.reset()
    }
  }

  // display() {
  //   this.p.noStroke()
  //   this.p.fill(255, 100)
  //   this.p.circle(this.pos.x, this.pos.y, this.size)
  // }
  // display() {
  //   // Main particle
  //   this.p.noStroke()
  //   this.p.fill(255, 100) // semi-transparent
  //   this.p.circle(this.pos.x, this.pos.y, this.size)
  //
  //   // Glow effect
  //   let glowSize = this.size * 3
  //   this.p.fill(255, 30) // more transparent
  //   this.p.circle(this.pos.x, this.pos.y, glowSize)
  // }
  // display() {
  //   // Subtle main particle
  //   let opacity = this.p.map(this.size, 1, 5, 25, 100) // smaller particles are more transparent
  //   this.p.noStroke()
  //   this.p.fill(255, opacity)
  //   this.p.circle(this.pos.x, this.pos.y, this.size)
  //
  //   // Subtle glow effect
  //   let glowSize = this.size * 2
  //   this.p.fill(255, 15) // very transparent for the glow
  //   this.p.circle(this.pos.x, this.pos.y, glowSize)
  // }
  display (lightSource) {
    // Calculate distance to the light source (e.g., mouse position)
    const d = this.p.dist(this.pos.x, this.pos.y, lightSource.x, lightSource.y)
    const maxDist = this.p.dist(0, 0, this.p.width / 2, this.p.height / 2)

    // Adjust opacity and size based on distance to the light source
    const opacity = this.p.map(d, 0, maxDist, 200, 10) // More visible closer to the light
    const sizeFactor = this.p.map(d, 0, maxDist, 1.5, 1) // Larger closer to the light

    // Main particle
    this.p.noStroke()
    this.p.fill(255, opacity)
    this.p.circle(this.pos.x, this.pos.y, this.size * sizeFactor)

    // Glow effect
    const glowSize = this.size * sizeFactor
    this.p.fill(255, opacity / 2) // The glow is more subtle
    this.p.circle(this.pos.x, this.pos.y, glowSize)

    this.color = this.p.color(
      this.p.random(200, 255),
      this.p.random(200, 255),
      this.p.random(200, 255)
    )
  }
}

const particles = []
// In your sketch, calculate the wind vector and pass it to the move function of each particle
let currentLightSource

export const DustParticles = {
  setup: (p) => {
    currentLightSource = p.createVector(p.width / 2, p.height / 2)
    // Call this function in your setup or initialization code
    setupDivHoverListener('events')
    p.createCanvas(p.windowWidth, p.windowHeight)
    for (let i = 0; i < 400; i++) {
      particles.push(new Particle(p))
    }
  },
  draw: (p) => {
    // p.background('rgba(0,0,0,1)')
    // p.background(0, 5)
    p.clear()
    const wind = P5.Vector.sub(
      p.createVector(p.mouseX, p.mouseY),
      p.createVector(p.width / 2, p.height / 2)
    )
    wind.normalize()
    wind.mult(0.1) // Reduce the overall influence of wind

    // Check if mouse is inside the canvas
    let targetLightSource
    if (
      p.mouseX > 20 &&
      p.mouseX < p.width - 20 &&
      p.mouseY > 20 &&
      p.mouseY < p.height - 20
    ) {
      targetLightSource = p.createVector(p.mouseX, p.mouseY)
    } else {
      // If mouse is outside canvas, set light source to center
      targetLightSource = p.createVector(p.width / 2, p.height / 2)
    }
    currentLightSource.x = p.lerp(
      currentLightSource.x,
      targetLightSource.x,
      0.03
    )
    currentLightSource.y = p.lerp(
      currentLightSource.y,
      targetLightSource.y,
      0.03
    )

    // Update and display particles
    for (const particle of particles) {
      particle.move(wind)
      particle.display(currentLightSource)
    }
  }
}
