'use strict'

export const PixelatedFrame = {
  props: {
    ignoreChildExtend: true,
    position: 'absolute',
    inset: '0 0 0 0',
    pointerEvents: 'none',
    zIndex: 2,
    background: 'dark 0.5 +26',
    border: 'dark 0.5 +10, X, solid',

    childProps: {
      position: 'absolute',
      boxSize: 'Z1',
      background: 'black',
      ':after, &:before': {
        content: '""',
        position: 'absolute',
        background: 'dark 1 +16'
      },
      ':after': { boxSize: 'X*2 X', left: 'X', top: 'X*2' },
      ':before': { boxSize: 'X X*2', left: 'X*2', top: 'X' }
    }
  },

  TopLeft: {
    left: '-X',
    top: '-X'
  },
  TopRight: {
    top: '-X',
    right: '-X',
    transform: 'rotate(90deg)'
  },
  BottomRight: {
    left: '-X',
    bottom: '-X',
    transform: 'rotate(270deg)'
  },
  BottomLeft: {
    bottom: '-X',
    right: '-X',
    transform: 'rotate(180deg)'
  }
}
