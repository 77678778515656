'use strict'

import { Link } from 'smbls'
import { TransitionGSAP } from './utils'

const rows = 16
const cols = 12

const startColor = { r: 25, g: 25, b: 25 }
const endColor = { r: 255, g: 255, b: 255 }

const generateGradientMatrix = (rows, cols, startColor, endColor) => {
  const matrix = []

  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      const ratioStart = (i * cols + j) / (rows * cols)
      const ratioEnd = (i * cols + j + 1) / (rows * cols)
      const colorStart = interpolateColor(startColor, endColor, ratioStart)
      const colorEnd = interpolateColor(startColor, endColor, ratioEnd)
      matrix.push({ start: colorStart, end: colorEnd })
    }
  }

  return matrix
}

const interpolateColor = (color1, color2, ratio) => {
  const r = Math.round(color1.r + (color2.r - color1.r) * ratio)
  const g = Math.round(color1.g + (color2.g - color1.g) * ratio)
  const b = Math.round(color1.b + (color2.b - color1.b) * ratio)
  return { r, g, b }
}

const rgbToCss = ({ r, g, b }) => {
  return `rgb(${r}, ${g}, ${b})`
}

const gradientMatrix = generateGradientMatrix(rows, cols, startColor, endColor)

const GridMatrix = new Array(cols * rows).fill(0).reduce((acc, _, i) => {
  const color = gradientMatrix[i]
  acc[`:nth-child(${i + 1})`] = {
    background: `linear-gradient(to right, ${rgbToCss(
      color.start
    )} , ${rgbToCss(color.end)})`
  }
  return acc
}, {})

export const Transition = {
  props: {
    position: 'fixed',
    top: '0',
    left: '0',
    boxSize: '100%',
    pointerEvents: 'none',
    zIndex: 5,
    opacity: 1
  },

  data: {
    showOnNode: true,
    cols: cols,
    rows: rows
  },

  on: {
    render: (el, s) => {
      s.transition = new TransitionGSAP(el)
    }
  },

  Logo: {
    position: 'absolute',
    fontSize: '3.5vmin',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 1px)',
    filter: 'shadow(0 0 0 10px var(--color-charm))',
    color: 'transparent'
  },

  Grid: {
    boxSize: '100%',
    templateColumns: 'repeat(var(--tt-columns), 1fr)',
    templateRows: 'repeat(var(--tt-rows), 1fr)',
    opacity: 1,
    '& div': {
      opacity: '0',
      // color: 'charm',
      // backgroundColor: 'currentColor',
      // boxShadow: '0 0 0 2px currentColor',
      ...GridMatrix
    }
  }
}

// const calculateGradient = (cols, rows) => {
//   const gradient = []
//   const random = () => Math.floor(Math.random() * 255)
//   for (let i = 0; i < cols * rows; i++) {
//     gradient.push(`rgb(${random()}, ${random()}, ${random()})`)
//   }
//   return gradient
// }

// Calculate the random continues gradient for each cell in the grid to generate single big gradient
// I need to have matrix of gradient colors for each cell in the grid
// Then I need to generate single gradient from this matrix
// Then I need to apply this gradient to the grid
// const calculateGradient = (cols, rows) => {
//   const gradient = []
//   const random = () => Math.floor(Math.random() * 255)
//   for (let i = 0; i < cols * rows; i++) {
//     gradient.push(`rgb(${random()}, ${random()}, ${random()})`)
//   }
//   return gradient
// }

//
// function rgbToCss({r, g, b}) {
//   return `rgb(${r}, ${g}, ${b})`;
// }

// function applyGradientToGrid(matrix, cols) {
//   const grid = document.getElementById('grid');
//   matrix.forEach((color, index) => {
//     const div = document.createElement('div');
//     div.classList.add('grid-item');
//     const background = `linear-gradient(to right, ${rgbToCss(color.start)} , ${rgbToCss(color.end)})`;
//     div.style.background = background;
//     grid.appendChild(div);
//   });
// }

export const TransitionTrigger = {
  on: {
    click: (ev, el, s) => {
      const transition = s.transition
      const Logo = el.__ref.__root.Transition.Logo

      transition.toggle('effect1', ev)

      setTimeout(() => {
        transition.toggle('effect1', ev)
      }, 450)

      setTimeout(() => {
        Logo.setProps({
          color: 'dark .85'
        })
      }, 100)

      setTimeout(() => {
        Logo.setProps({
          color: 'transparent'
        })
      }, 650)

      setTimeout(() => {
        Link.on.click(ev, el, s)
      }, 420)
    }
  }
}
