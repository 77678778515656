'use strict'

export const Knob = {
  props: {
    position: 'relative',
    boxSize: 'B',
    class: 'knob',
    Text: {
      position: 'absolute',
      transform: 'translate3d(-50%, 0, 1px)',
      top: '130%',
      textAlign: 'center',
      left: '50%',
      fontSize: 'Y'
    }
  },
  Tick: {
    data: {
      showOnNode: true,
      effectName: (el) => el.parent.Text.text.toLowerCase()
    },
    props: {
      class: 'tick',
      position: 'absolute',
      inset: '0 0 0 0',
      transform: 'rotate(-135deg)',
      color: '#f1bcb2',
      cursor: 'row-resize',
      ':after': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '50%',
        width: '2px',
        color: 'currentColor',
        background: 'currentColor',
        margin: '- - - -1px',
        bottom: '50%'
      }
    }
  },

  Box: {
    props: {},
    html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 43"><path fill="currentColor" fill-rule="evenodd" d="M1456,936 L1456,938 L1461,938 L1460.999,940 L1474.999,940 L1475,938 L1480,938 L1480,936 L1482,936 L1482,931 L1484.5,931 L1484.5,916 L1482,916 L1482,911 L1480,911 L1480,909 L1475,909 L1474.999,907 L1460.999,907 L1461,909 L1456,909 L1456,911 L1454,911 L1454,916 L1451.5,916 L1451.499,931 L1454,931 L1454,936 L1456,936 Z M1449,936 L1448.999,932.5 L1446.5,932.5 L1446.5,914.5 L1448.999,914.499 L1449,911 L1451,911 L1451,906 L1456,906 L1456,904 L1458.999,904 L1459,902 L1477,902 L1476.999,904 L1480,904 L1480,906 L1485,906 L1485,911 L1487,911 L1486.999,914.499 L1489.5,914.5 L1489.5,932.5 L1486.999,932.5 L1487,936 L1485,936 L1485,941 L1480,941 L1480,943 L1476.999,943 L1477,945 L1459,945 L1458.999,943 L1456,943 L1456,941 L1451,941 L1451,936 L1449,936 Z" transform="translate(-1446 -902)"/></svg>'
  }
}
