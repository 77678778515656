'use strict'

export const Header = {
  extend: 'Flex',
  props: {
    gap: 'C1',
    padding: 'B C1',
    width: '100%'
  }
}
