'use strict'

export const About = {
  props: {
  },

  Content: {
    props: {
      flow: 'column',
      position: 'relative',
      zIndex: 1,
      width: '100%',
      maxWidth: 'I3',
      margin: 'auto',
      align: 'center center',
      textAlign: 'center',
      ragac: true
    },

    H3: {
      text: 'The monthly party at ://about blank is breaking with tradition by not announcing lineups.'
    },

    P: {
      text: 'How STAUB, A “Techno Communist” Day Party, Quietly Became One Of Berlin’s Best Events'
    }
  },

  Flex: {
    props: {
      maxWidth: 'J3',
      gap: 'C1',
      margin: 'D1 auto',
      width: '100%',

      childProps: { flex: 1 }
    },

    Poster: {
      props: { flex: 1 },
      Img: {
        width: '100%',
        src: 'https://res.cloudinary.com/electronic-beats/c_fit,q_auto,f_auto,w_3840/stage/uploads/2019/02/STAUB-lead-image-by-george-nebieridze.jpg',
        boxShadow: 'Z2, Z2, 0, 0, white .05'
      }
    },

    Flex: {
      props: {
        flow: 'column',
        flex: 1,
        gap: 'A',
        fontSize: 'A2',
        fontFamily: 'ui'
      }

      // $collection: [
      //   'STAUB is an event series in Berlin. It is known for its unique atmosphere and diverse musical offerings, spanning from Techno, House, and Disco to Ambient and experimental electronic music. Notably, STAUB hosts daytime parties that often extend into the evening. In addition to contributing to the vibrancy of Berlin\'s party scene, STAUB is committed to inclusivity and supporting newcomers and young talents, making it a significant hub of cultural and musical diversity.',
      //   'The STAUB Party goes beyond being just a day of electronic music. It serves as a space where people of various backgrounds and lifestyles come together to celebrate and express themselves. The focus of STAUB Party is not on revealing line-ups or artist names but on the music and the experience that guests share at the event. Artists remain a surprise, fostering an element of unpredictability for attendees. This commitment to mystery extends to vinyl releases on the label "Intergalactic Research Institute for Sound," where artist names are deliberately omitted, emphasizing the emphasis on the music itself rather than individual names. This approach allows STAUB Party and the label to place the spotlight on the music, providing guests with a unique and surprising experience.',
      //   'Expanding the concept to cities like Tbilisi, Detroit, New York, Copenhagen, and many others has allowed us to broaden our audience and draw inspiration from diverse cultural and musical contexts. STAUB Party is a monthly event dedicated to musical experimentation, inclusion, diversity, and supporting electronic dance music and its rooted subculture. It is a space for people to come together, express themselves, have fun, and discover new music. As an integral part of our mission to create a better future, STAUB places a strong emphasis on label and creative production, ensuring that the focus remains on the music and the shared experience.'
      // ]
    }
  },

  Content_2: {
    extend: 'Flex',
    props: {
      flow: 'column',
      position: 'relative',
      zIndex: 1,
      width: '100%',
      maxWidth: 'I3',
      margin: 'auto',
      align: 'center center',
      textAlign: 'center',
      ragac: true
    },

    Flex_1: {
      props: {
        gap: 'A1',
        childProps: { flex: 1, textAlign: 'justify' }
      },
      Italic: {
        margin: 'A',
        fontFamily: 'ui',
        text: 'STAUB, a monthly daytime event that takes place the last Saturday of every month at ://about blank, is one of Berlin’s best parties. But it’s historically operated behind a shroud of anonymity; the lineups are never announced, the STAUB Records artists are never revealed and the promoters skirt the sidelines of the dance floors, never putting themselves in the limelight.'
      },

      Italic_2: {
        margin: 'A',
        fontFamily: 'ui',
        text: 'In honor of the party’s six-year anniversary this weekend, we decided to take a deeper look at its impact on the scene. TEB editor Chloé Lula traveled to Detroit to see STAUB’s United States debut and discussed the party’s philosophy with its three organizers, Irakli, Ines and Jan (pictured above).'
      }
    },

    Divider: {},

    P_2: {
      fontFamily: 'ui',
      text: 'It’s twilight on Monday, May 28 and the sun is setting over the Detroit skyline. A tired but exuberant crowd is dancing on the back patio of the downtown venue TV Lounge, an off-Movement Festival space that plays host to afterparties throughout the duration of the country’s largest and oldest electronic music event. But on this final day of the Memorial Day weekend, the crowd isn’t dancing to the more standard musical offerings of local talent or US-based techno artists; they’re dancing to STAUB, a Berlin-based party that only recently touched down in Detroit.'
    },

    P_3: {
      fontFamily: 'ui',
      text: 'STAUB’s appearance in the Motor City was its first in the US, and its famously anonymous booking concept fell counter to the heavily promoted Movement festivities that were happening a few blocks away. That the undisclosed STAUB headliners included major acts that played at the much more heavily publicized—and costly—festival the weekend before was not received without a touch of irony from the guests who were able to identify them.'
    },

    Flex_soundcloud: {
      props: {
        width: '100%',
        margin: 'C',
        gap: 'Z',
        flow: 'column'
      },

      Iframe: {
        width: '100%',
        minHeight: 'none',
        height: '166px',
        scrolling: 'no',
        frameborder: 'no',
        allow: 'autoplay',
        src: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/576853176&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true'
      },

      Italic: {
        fontSize: 'Z1',
        fontFamily: 'ui',
        text: 'Lorem inpsum from the guests who were able to identify them.'
      }
    }
  }
}

export const AboutWithBackground = {
  props: {
    ':before': {
      content: '""',
      opacity: '.35',
      position: 'absolute',
      zIndex: 0,
      inset: '0 0 0 0',
      background: 'url(https://res.cloudinary.com/electronic-beats/c_fit,q_auto,f_auto,w_3840/stage/uploads/2019/02/STAUB-lead-image-by-george-nebieridze.jpg) center center',
      backgroundSize: 'cover'
    },
    ':after': {
      content: '""',
      opacity: '.35',
      position: 'absolute',
      zIndex: 0,
      inset: '0 0 0 0',
      background: 'dark 1 -250'
    }
  },

  Flex: {
    props: {
      flow: 'column',
      position: 'relative',
      zIndex: 1,
      width: '100%',
      maxWidth: 'I3',
      margin: 'auto',
      align: 'center center',
      textAlign: 'center'
    },

    H3: {
      text: 'The monthly party at ://about blank is breaking with tradition by not announcing lineups.'
    },

    P: {
      text: 'How STAUB, A “Techno Communist” Day Party, Quietly Became One Of Berlin’s Best Events'
    }
  }
}
