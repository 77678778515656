'use strict'

import { deepClone } from '@domql/utils'

const effectValues = {
  freq: {
    min: 10,
    max: 22050,
    default: 10,
    step: 100,
    value: 10
  },
  res: {
    min: 1,
    max: 100,
    default: 1,
    step: 1,
    value: 1
  },
  amp: {
    min: 0,
    max: 100,
    default: 0,
    step: 1,
    value: 0
  },
  delay: {
    min: 0,
    max: 99,
    default: 0,
    step: 1,
    value: 0
  },
  feedback: {
    min: 0,
    max: 99,
    default: 0,
    step: 1,
    value: 0
  },
  filter: {
    min: 0,
    max: 22050,
    default: 0,
    step: 100,
    value: 0
  },
  reverb: {
    min: 0,
    max: 100,
    default: 0,
    step: 1,
    value: 0
  },
  decay: {
    min: 0,
    max: 100,
    default: 0,
    step: 1,
    value: 0
  },
  pan: {
    min: -99,
    max: 99,
    default: 0,
    step: 1,
    value: 0
  }
}

const initialSequencerPattern = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

export const BPM = {
  min: 50,
  max: 190,
  default: 120,
  step: 1,
  value: 120
}

export const PreloadSounds = (p) => {
  p.soundFormats('mp3', 'ogg', 'wav')

  // kick1
  const kick1Sound = p.loadSound(require('url:../../assets/sound/kick_1.wav'))
  const kick1Background = new URL(
    '../../assets/galaxian.svg',
    import.meta.url
  ).toString()
  // kick2
  const kick2Sound = p.loadSound(require('url:../../assets/sound/kick_2.wav'))
  const kick2Background = new URL(
    '../../assets/apple.svg',
    import.meta.url
  ).toString()
  // snare
  const snareSound = p.loadSound(require('url:../../assets/sound/snare.wav'))
  const snareBackground = new URL(
    '../../assets/melon.svg',
    import.meta.url
  ).toString()
  // rim
  const rimBackground = new URL(
    '../../assets/orange.svg',
    import.meta.url
  ).toString()
  const rimSound = p.loadSound(require('url:../../assets/sound/rim-shot.wav'))
  // hat-closed
  const hatClosedSound = p.loadSound(
    require('url:../../assets/sound/hat-closed.wav')
  )
  const hatClosedBackground = new URL(
    '../../assets/cherry.svg',
    import.meta.url
  ).toString()
  // hat-open
  const hatOpenSound = p.loadSound(
    require('url:../../assets/sound/hat-open.wav')
  )
  const hatOpenBackground = new URL(
    '../../assets/blinky_right_angry.svg',
    import.meta.url
  ).toString()
  // tom-high
  const tomHighSound = p.loadSound(
    require('url:../../assets/sound/tom-high_yamaha.wav')
  )
  const tomHighBackground = new URL(
    '../../assets/bell.svg',
    import.meta.url
  ).toString()
  // tom-low
  const tomLowSound = p.loadSound(
    require('url:../../assets/sound/tom-low-floor.wav')
  )
  const tomLowBackground = new URL(
    '../../assets/key.svg',
    import.meta.url
  ).toString()
  // cymbal
  const cymbalSound = p.loadSound(require('url:../../assets/sound/cymbal.wav'))
  const cymbalBackground = new URL(
    '../../assets/pinky_right.svg',
    import.meta.url
  ).toString()
  // maracas
  const maracasSound = p.loadSound(
    require('url:../../assets/sound/maracas.wav')
  )
  const maracasBackground = new URL(
    '../../assets/clyde_up.svg',
    import.meta.url
  ).toString()
  // zap
  const zapSound = p.loadSound(require('url:../../assets/sound/zap_v1.wav'))
  const zapBackground = new URL(
    '../../assets/blinky_right.svg',
    import.meta.url
  ).toString()
  // sample
  const sampleSound = p.loadSound(require('url:../../assets/sound/zap_v2.wav'))
  const sampleBackground = new URL(
    '../../assets/inky_down.svg',
    import.meta.url
  ).toString()

  return {
    kick1: {
      sound: kick1Sound,
      image: kick1Background,
      effects: {},
      effectValues: deepClone(effectValues),
      pattern: deepClone(initialSequencerPattern),
      phrase: null,
      el: null,
      playing: false
    },
    kick2: {
      sound: kick2Sound,
      image: kick2Background,
      effects: {},
      effectValues: deepClone(effectValues),
      pattern: deepClone(initialSequencerPattern),
      phrase: null,
      el: null,
      playing: false
    },
    snare: {
      sound: snareSound,
      image: snareBackground,
      effects: {},
      effectValues: deepClone(effectValues),
      pattern: deepClone(initialSequencerPattern),
      phrase: null,
      el: null,
      playing: false
    },
    rim: {
      sound: rimSound,
      image: rimBackground,
      effects: {},
      effectValues: deepClone(effectValues),
      pattern: deepClone(initialSequencerPattern),
      phrase: null,
      el: null,
      playing: false
    },
    'hat-closed': {
      sound: hatClosedSound,
      image: hatClosedBackground,
      effects: {},
      effectValues: deepClone(effectValues),
      pattern: deepClone(initialSequencerPattern),
      phrase: null,
      el: null,
      playing: false
    },
    'hat-open': {
      sound: hatOpenSound,
      image: hatOpenBackground,
      effects: {},
      effectValues: deepClone(effectValues),
      pattern: deepClone(initialSequencerPattern),
      phrase: null,
      el: null,
      playing: false
    },
    'tom-high': {
      sound: tomHighSound,
      image: tomHighBackground,
      effects: {},
      effectValues: deepClone(effectValues),
      pattern: deepClone(initialSequencerPattern),
      phrase: null,
      el: null,
      playing: false
    },
    'tom-low': {
      sound: tomLowSound,
      image: tomLowBackground,
      effects: {},
      effectValues: deepClone(effectValues),
      pattern: deepClone(initialSequencerPattern),
      phrase: null,
      el: null,
      playing: false
    },
    cymbal: {
      sound: cymbalSound,
      image: cymbalBackground,
      effects: {},
      effectValues: deepClone(effectValues),
      pattern: deepClone(initialSequencerPattern),
      phrase: null,
      el: null,
      playing: false
    },
    maracas: {
      sound: maracasSound,
      image: maracasBackground,
      effects: {},
      effectValues: deepClone(effectValues),
      pattern: deepClone(initialSequencerPattern),
      phrase: null,
      el: null,
      playing: false
    },
    zap: {
      sound: zapSound,
      image: zapBackground,
      effects: {},
      effectValues: deepClone(effectValues),
      pattern: deepClone(initialSequencerPattern),
      phrase: null,
      el: null,
      playing: false
    },
    sample: {
      sound: sampleSound,
      image: sampleBackground,
      effects: {},
      effectValues: deepClone(effectValues),
      pattern: deepClone(initialSequencerPattern),
      phrase: null,
      el: null,
      playing: false
    }
  }
}
