'use strict'

import P5 from './modules/p5.min'
import './modules/p5.sound.min'

import { create } from 'smbls'

import designSystem from './designSystem'
import * as components from './components'
import state from './state'
import pages from './pages'

// import { SpaceParticles } from './components/SpaceParticles/utils'
import { DrumMachine } from './components/Controller/utils'
import { DustParticles } from './components/DustParticles/utils'

create({
  extend: 'Flex',

  data: {
    preload: [DrumMachine.preload],
    setup: [DustParticles.setup, DrumMachine.setup],
    draw: [DustParticles.draw],
    p5instance: null
  },

  props: {
    theme: 'document',
    flow: 'column',
    height: '100vh',
    align: 'center space-between',

    content: {
      flow: 'column',
      flex: '1 1 auto',
      padding: 'C1',
      zIndex: 1,
      order: 2,

      ':after': {
        content: '""',
        position: 'fixed',
        bottom: '0',
        left: '0',
        right: '0',
        height: 'F',
        zIndex: 1,
        background: 'linear-gradient(0deg, rgba(0,0,0,1) 15%, rgba(0,0,0,0) 100%)'
      }
    },
    '& .p5Canvas': {
      position: 'fixed',
      inset: '0 0 0 0',
      zIndex: 0
    }
  },

  // Loading: {},

  // Navigation: {},
  Transition: {},
  // SpaceParticles: { hide: true },
  DustParticles: { hide: true },

  on: {
    render: (el, s) => {
      const p5instance = new P5((p) => {
        p.preload = () => el.data.preload.forEach((callback) => callback(p))
        p.setup = () => el.data.setup.forEach((callback) => callback(p))
        p.draw = () => el.data.draw.forEach((callback) => callback(p))
      }, el.node)
      el.data.p5instance = p5instance
      return p5instance
    }
  }
}, {
  state,
  designSystem,
  components,
  pages
})
