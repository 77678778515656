'use strict'

import { TransitionTrigger } from './Transition'

export const Logo = {
  extend: [TransitionTrigger],
  props: {
    href: '/',
    // color: 'charm',
    zIndex: '3'
  },
  Icon: {
    icon: 'staub',
    fontSize: 'I3'
  }
}
