'use strict'

import { SpaceParticles as ParticleSystem } from './utils'

export const SpaceParticles = {
  props: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    zIndex: 1
  },
  on: {
    render: (el, s) => {
      el.__ref.__root.data.setup.push(ParticleSystem.setup)
      el.__ref.__root.data.draw.push(ParticleSystem.draw)
    }
  }
}
