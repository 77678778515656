'use strict'

export const ComingSoon = {
  extend: ['Page'],

  H2: {
    position: 'absolute',
    maxWidth: '100%',
    left: '50%',
    top: '50%',
    transform: 'translate3d(-50%, -50%, 1px)',
    textAlign: 'center',
    text: 'coming soon...'
  },

  on: {
    render: (el, s) => {
      if (el.parent.data.p5instance) el.parent.data.p5instance.setup()
    }
  }
}
