'use strict'

import { Link } from 'smbls'
import { TransitionTrigger } from './Transition'

export const Navigation = {
  extend: [TransitionTrigger, 'Flex'],
  tag: 'nav',

  props: () => {
    const location = window.location.pathname.split('/')[1]
    let fontFamily = 'ui'
    if (location === 'controller') {
      fontFamily = 'game'
    }
    return {
      gap: 'D3',
      align: 'center',
      zIndex: 2,
      pointerEvents: 'none',
      order: 3,
      padding: 'B D1',
      fontFamily: fontFamily,
      position: 'sticky',
      bottom: '0',
      backdropFilter: 'blur(3px)',
      background: 'black 0.25',
      borderBottom: 'none'
    }
  },

  PixelatedFrame: {
    BottomRight: { hide: true },
    BottomLeft: { hide: true }
  },

  childExtend: {
    props: {
      fontWeight: '500',
      lineHeight: 1,
      letterSpacing: '3px',
      pointerEvents: 'auto'
    },
    on: {
      click: (ev, el, s) => {
        setTimeout(() => {
          Link.on.click(ev, el, s)
        }, 250)
      }
    }
  },

  Logo: { height: 'C', overflow: 'hidden', flexAlign: 'center' },

  $collection: () => [{
    text: 'Home',
    href: '/'
  }, {
    text: 'Events',
    href: '/events'
  }, window.location.pathname === '/about'
    ? {
        text: 'About (2)',
        href: '/about2'
      }
    : {
        text: 'About',
        href: '/about'
      }, {
    text: 'Contact',
    href: '/contact'
  }].map(props => ({ props }))
}
