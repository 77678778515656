'use strict'

export default {
  controller: {
    bpm: 120,
    screenValue: 0
  },
  main: {
    data: {
      looping: false,
      myPart: {},
      sounds: {},
      phrases: {},
      patterns: {}
    }
  }
}
